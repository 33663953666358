<template>
  <el-select
    :class="[inputClasses]"
    style="text-transform: uppercase"
    v-model="stateModel"
    :name="$t('COMMON.STATES')"
    :multiple="multiple"
    :filterable="filterable"
    @change="stateChanged"
  >
    <el-option
      :class="[inputClasses]"
      v-for="state in states"
      :key="state[0]"
      :value="(state[0].toString().toLowerCase() !== 'québec' && state[0].toString().toLowerCase() !== 'quebec') ? state[0] : 'Quebec'"
      :label="(state[0].toString().toLowerCase() !== 'québec' && state[0].toString().toLowerCase() !== 'quebec') ? state[0] : $t('COMMON.QUEBEC')"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";
import { allCountries } from "country-region-data";

export default {
  name: "state-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    country: {
      type: String,
      default: null,
      description: "Country id",
    },
    state: {
      type: String,
      default: null,
      description: "State id",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    inputClasses: {
      type: String,
      description: "Input css classes",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
  },

  data() {
    const selectedCountry = allCountries.find((el) => el[0] === this.country);
    let states = [];
    if (selectedCountry) {
      states = selectedCountry[2];
    }
    return {
      stateModel: states.find((el) => el[0] === this.state) ? this.state : null,
      states: states,
    };
  },

  setup() {},

  created() {},

  methods: {
    stateChanged(state) {
      this.$emit("stateChanged", (state.toString().toLowerCase() !== 'québec' && state.toString().toLowerCase() !== 'quebec') ? state : 'Québec');
    },
  },

  watch: {
    state(state) {
      this.stateModel = state !== "Québec" ? state : 'Quebec';
    },
    country(country) {
      const selectedCountry = allCountries.find((el) => el[0] === country);
      if (selectedCountry) {
        this.states = selectedCountry[2];
        if (!this.states.find((el) => el[0] === this.state)) {
          this.stateModel = null;
        }
      }
    },
  },
};
</script>
