<template>
  <div class="elite-tabs-wrapper-content">
    <div class="custom-header">
      <h3>{{ `${invitation.name}` }} - {{ $t("COMMON.LOGS") }}</h3>
    </div>
    <log-list-table :filterSubject="invitation" />
  </div>
</template>
<script>
import LogListTable from "@/views/Pages/DefaultModule/LogManagement/partials/LogListTable.vue";

export default {
  name: "invitation-view-logs",

  components: { LogListTable },

  props: ["invitation"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
