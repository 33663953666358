<template>
  <div>
    <div style="display: inline">
      <badge :type="getDeliveryBadgeType()">
        {{ $t(`INVITATIONS.STATUS_${invitation.status}`) }}
      </badge>
    </div>
  </div>
</template>

<script>
import {
  STATUS_APPROVED,
  STATUS_WAITING,
  // STATUS_REJECTED,
} from "@/constants/invitations";

export default {
  name: "invitation-status-badge-component",

  components: {},

  mixins: [],

  props: ["invitation"],

  data() {
    return {};
  },

  computed: {},

  methods: {
    getDeliveryBadgeType() {
      switch (this.invitation.status) {
        case STATUS_APPROVED:
          return "success";
        case STATUS_WAITING:
          return "primary";
        // case STATUS_REJECTED:
        //   return "danger";
        default:
          break;
      }
      return "default";
    },
  },

  mounted() {},

  watch: {},
};
</script>
