<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!Invitation">
      <span class="loader"></span>
    </span>
    <Invitation-form
      v-if="Invitation"
      :loading="loading"
      :invitationData="Invitation"
      :formErrors="formErrors"
      @invitationSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import InvitationForm from "../partials/InvitationForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";

export default {
  layout: "DashboardLayout",

  components: { InvitationForm },

  mixins: [alertLeave],

  props: {
    invitationId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      Invitation: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("invitations/get", this.invitationId);
        this.Invitation = this.$store.getters["invitations/invitation"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async handleSubmit(Invitation) {
      this.loading = true;
      const invitationData = cloneDeep(Invitation);

      try {
        await this.$store.dispatch("invitations/update", invitationData);
        this.$notify({
          type: "success",
          message: this.$t("INVITATIONS.INVITATION_UPDATED"),
        });
        this.$emit("onViewInvitation", Invitation, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
