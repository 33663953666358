<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ invitation.dealer_name }}</h3>
    <div class="col-12">
    
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd class="col-sm-8">
            {{ invitation.first_name }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            <div v-html="invitation.last_name"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
          <dd class="col-sm-8">
            {{ invitation.email }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.PHONE") }}
          </dt>
          <dd class="col-sm-8">
            
            {{ invitation.mobile }}
          
          </dd>
        </dl>
        
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8">
            {{ invitation.address }}
          </dd>
        </dl>
       
       
      
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $formatDate(invitation.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $formatDate(invitation.updated_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
          <dd class="col-sm-8">
            {{ invitation.status}}
          </dd>
        </dl>
        <div class="mb-2 mt-4">
          <h3>{{ $t("COMMON.BILLING_ADDRESS") }}</h3>
        </div>

        <billing-informations-view :billingInformations="invitation" />
      </div>
    </div>
  </div>
</template>

<script>
import models from "@/constants/models";
import { TAX_TYPE_PERCENTAGE, TAX_TYPE_AMOUNT } from "@/constants/taxes";
import BillingInformationsView from "@/components/BillingInformationsView.vue";

export default {
  name: "invitation-view-global",

  components: { BillingInformationsView },

  props: ["invitation"],

  data() {
    return {
      models: models,
      TAX_TYPE_AMOUNT: TAX_TYPE_AMOUNT,
      TAX_TYPE_PERCENTAGE: TAX_TYPE_PERCENTAGE,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    invitation(invitation) {},
  },
};
</script>
