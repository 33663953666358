<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
  

  <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.DEALER_NAME')} (*)`"
        :placeholder="$t('COMMON.DEALER_NAME')"
        v-model="invitation.dealer_name"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.dealer_name" />
    </div>

    <div class="form-wrapper">
     <div class="equal-item type">
          <base-input :label="`${$t('COMMON.TYPE')}`">
            <el-select
              :label="$t('COMMON.TYPE')"
              :placeholder="$t('COMMON.TYPE')"
              v-model="invitation.merchand_type"
              @change="
                (type) => {
                  invitation.merchand_type = type;
                  onFormChanged();
                }
              "
            >
              <el-option
                v-for="(value, key) in merchandsOptions"
                :key="key"
                :value="value"
                :label="$t(`COMMON.${value}`)"
              />
            </el-select>
          </base-input>
        </div>

      <validation-error :errors="apiValidationErrors.name" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.FIRSTNAME')} (*)`"
        :placeholder="$t('COMMON.FIRSTNAME')"
        v-model="invitation.first_name"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.name" />
    </div>
    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.NAME')} (*)`"
        :placeholder="$t('COMMON.NAME')"
        v-model="invitation.last_name"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.name" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.EMAIL')} (*)`"
        :placeholder="$t('COMMON.EMAIL')"
        v-model="invitation.email"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.email" />
    </div>

    <!-- Phone number -->
    <div class="form-wrapper full">
      <div class="row equal">
       
        <div class="equal-item phone">
          <base-input :label="`${$t('COMMON.PHONE')}`">
            <phone-number-input
              :phoneNumber="invitation.mobile"
              @phoneNumberChanged="
                (mobile) => {
                  invitation.mobile = mobile;
                  onFormChanged();
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.mobile" />
        </div>
       
      </div>
    </div>


    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.ADDRESS')}`"
        :placeholder="$t('COMMON.ADDRESS')"
        v-model="invitation.address"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.address" />
    </div>

    <div class="form-wrapper taxes full">
      <base-input
        :label="$t('COMMON.TPS_TAXES')"
        v-model="invitation.tps"
        @taxesChanged="
          (tps) => {
            invitation.tps = tps;
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.taxes" />
    </div>
    <div class="form-wrapper taxes full">
      <base-input
        :label="$t('COMMON.TVQ_TAXES')"
        v-model="invitation.tvq"
        @taxesChanged="
          (tps) => {
            invitation.tps = tvq;
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.taxes" />
    </div>
    <h2 class="col-12 mb-3">{{ $t("COMMON.BILLING_ADDRESS") }}</h2>

    <billing-informations-form
      :billingInformationsData="invitation"
      :formErrors="formErrors"
      @onChangeBillingInformations="billingInformationsChanged"
    />

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{
          invitation.id
            ? $t("INVITATIONS.EDIT_INVITATION")
            : $t("INVITATIONS.ADD_INVITATION")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import models from "@/constants/models";
import { merchandsOptions } from "@/constants/common";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BillingInformationsForm from "@/components/BillingInformationsForm.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    BillingInformationsForm,
    [Select.name]: Select,
    [Option.name]: Option,
    PhoneNumberInput,
  },

  mixins: [formMixin],

  props: ["invitationData", "formErrors", "loading"],

  data() {
    return {
      invitation: { ...this.invitationData },
      permissions: [],
      models: models,
      merchandsOptions,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let invitationData = cloneDeep(this.invitation);
      console.log("invitationData", invitationData)
      this.$emit("invitationSubmitted", invitationData);
    },

    billingInformationsChanged(billingInformations) {
      this.invitation = { ...this.invitation, ...billingInformations };
      this.onFormChanged();
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    invitationData(invitationData) {
      if (invitationData) {
        this.invitation = {
          ...this.invitation,
          ...cloneDeep(invitationData),
        };
      }
    },
  },
};
</script>
